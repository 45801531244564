
import logo from '../../../assets/header.png';

function Done() {
  
  return (
    
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Evaluación Terminada
        </p>
      
      </header>
    </div>
  );
}


export default Done;

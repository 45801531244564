import React, { useState, useEffect, useRef } from 'react';
import { loadIssueType, get_models, get_model_issues, save_new_model_issue, add_model } from '../../utils/services';
import './Models_admin.css';
import { useNavigate } from 'react-router-dom';

const Models_admin = ({usr_id, permission}) => {

  const token = localStorage.getItem("token");
  const [deviceMSG, setIssues] = useState([]);



  const [newType, setNewType] = useState("");
  const modelInputRef = useRef(null)



  const [reference, setreference] = useState();
  const newAnnotationText = useRef(null)
  const navigate = useNavigate();
  const [creation_date, setcreation_date] = useState();
  const [dropdownOptions, setDropdownOptions] = useState([]); // Estado para las opciones del dropdown
  const [dropdownType, setDropdownType] = useState([]); // Estado para las opciones del dropdown
  useEffect(() => {

    async function fetch_issueType() {
      const issueTypelist = await loadIssueType(token);
      if (issueTypelist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista: " + issueTypelist[0].name_issue_type);
        const issueTypeNames = issueTypelist.map((element, index) => {
            return { id: element.id, name: element.name_issue_type, index: index };
          });

        issueTypeNames.unshift("Seleccione uno...");
        setDropdownType(issueTypeNames);
        
     }
    }

    fetch_issueType();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  async function fetchgetModels() {
    const packageslist = await get_models(token);
    if (packageslist==="INVALID"){
      navigate("/");
    }else{

    
      
      const packageNames = packageslist.map(element => element.reference);
      packageNames.unshift("Select one...");
      setDropdownOptions(packageNames);
      
   }
  }

  useEffect(() => {


    fetchgetModels();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia





  const newAnnotation = () => {
    console.log ("Anotacion nueva> " + newAnnotationText.current.value  + " type:" + newType + " usrid" + usr_id);
      if(newType === ''){
        alert("Seleccione un tipo");
        return;
      }
      if (reference === undefined){
        alert("Seleccione un modelo");
      }

    async function fetchSaveModel_issue(model_ref) {
      
       
        const deviceMessages = await save_new_model_issue(token, model_ref, newAnnotationText.current.value, newType, usr_id );
        if (deviceMessages==="INVALID"){
          navigate("/");
        }else{
            newAnnotationText.current.value = "";
            findbycombo(reference);
       }
      }
  
      fetchSaveModel_issue(reference);

  }

  const findbycombo = (model_ref) => {

    setreference(model_ref);
    async function fetchGetmodel(model_ref) {
      
       
      const modelMessages = await get_model_issues(token, model_ref);
      if (modelMessages==="INVALID"){
        navigate("/");
      }else{



        setcreation_date (modelMessages[0].creation_date);

         const modelIssues = modelMessages["issues"].map(item => ({
          comment: item.comment,
          date: item.date,
          usr_name: item.usr_name,
          id: item.id,
          name_issue_type:item.name_issue_type
        }));

        setIssues(modelIssues);

     }
    }

    fetchGetmodel(model_ref);
  };


  const handleAddBt = () => {

 
    async function fetchAddModel(new_model) {
      
       
      const deviceMessages = await add_model(token, new_model);
      if (deviceMessages==="INVALID"){
        navigate("/");
      }else{
         fetchgetModels();

     }
    }

    fetchAddModel(modelInputRef.current.value);
  };



  return (
    <div className="container">
     


      <div className="dropdown">
        <select className="dropdown-select" onChange ={(e) => findbycombo(e.target.value)}>
           {dropdownOptions.map((option, index) => (
            <option key={index} value={option}  >
              {option}
            </option>
          ))}
        </select>

        {permission === "1" ? 
   
            <div className='newModel'>

            <input  type="text" id="new_model" ref={modelInputRef} />
            <button onClick={handleAddBt}>Add</button>
            </div>
        : null}
        
      </div>

        <label>Creado: {creation_date}</label>
        {permission === "1" ? 
            <div >
                <label>Crear nuevo modelo:</label>
            </div>
        : null}

    
      <div className="annotations">
        {deviceMSG.map((anno) => (
          <div key={anno.id} className="annotation">
            <div className="header">
              <span>by: {anno.usr_name} - {anno.date}</span>
              <span className="status">
                {anno.name_issue_type}
                <div className="status-dot"></div>
              </span>
            </div>
            <div className="content" style={{ whiteSpace: "pre-line" }}>
              <p>{anno.comment}</p>
            </div>
          </div>
        ))}
      </div>

      
      
      {permission === "1" ? 
      <div className="new-annotation">
        <label htmlFor="annotation-text">New annotation: </label>
        <textarea
            id="annotation-text"
            rows="4"
            cols="50"
            ref={newAnnotationText}
        />
    
        <select   onChange={(e) => setNewType(e.target.value)}>
                {dropdownType.map(({ id, name }, index) => (
                    <option key={id} value={id}  >
                    {id} {name}
                    </option>
                ))}
         </select>
        
        <button onClick={newAnnotation} >Save</button>
        </div>
        :null}
    </div>


  );
};

export default Models_admin;

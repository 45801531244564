import React, { useEffect, useRef, useState } from 'react';
import './TalentStep2.css';
import {  create_contributions, get_checkpoints,  get_member_selected_cards, get_members_cp_card, peso } from '../../../utils/talent_services';
import { useNavigate } from 'react-router-dom';

function TalentStep2({ usr_id }) {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [cards, setCards] = useState([]);
  const [currentMembers, setCurrentMembers] = useState([ ]);
  const [current_tarjeta, setcurrent_tarjeta] = useState({
    cpCardid: 0,
    cardname: "",
    shortUrl: "",
    index: 0,
  });
  const [indice, setIndice] = useState(0);

  const validate_last_checkpoint = () => {
    async function fetchCheckpoints() {
      const checkpointlist = await get_checkpoints(token);
      if (checkpointlist === "INVALID") {
        navigate("/");
      } else {
        let checkPoint_id = checkpointlist[0].id;
        const card_to_evaluate = await get_member_selected_cards(
          token,
          checkPoint_id
        );
        if (card_to_evaluate === "INVALID") {
          navigate("/");
        } else {
          const currentKr = card_to_evaluate.map((celement, index) => {
            return {
              id: celement.id,
              cardid: celement.card_id,
              cardname: celement.title,
              shortUrl: celement.shortURL,
              index: index,
            };
          });
          setCards(currentKr);
          setIndice(0);
          setcurrent_tarjeta(currentKr[indice]);
        }
      }
    }
    fetchCheckpoints();
  };

  const get_selected_card_members = () => {
    async function fetchCheckpoints() {
      const checkpointlist = await get_checkpoints(token);
      if (checkpointlist === "INVALID") {
        navigate("/", { state: { parametro: "talent_step2" } });
      } else {
        let checkPoint_id = checkpointlist[0].id;
        const card_members = await get_members_cp_card(
          token,
          checkPoint_id,
          current_tarjeta.cardid
        );
        if (card_members === "INVALID") {
          navigate("/");
        } else {
          const current_card_members = card_members.map((celement, index) => {
            return {
              memberid: celement.member_id,
              usr_name: celement.usr_name,
              index: index,
            };
          });
          setCurrentMembers(current_card_members);
        }
      }
    }
    fetchCheckpoints();
  };

  const handleDragStart = (e, index) => {
    console.log("Start : " + index );
    e.dataTransfer.setData("draggedIndex", index);
  };

  const handleDrop = (e, index) => {
    
    const draggedIndex = e.dataTransfer.getData("draggedIndex");
    const newMembers = [...currentMembers];
    const [draggedMember] = newMembers.splice(draggedIndex, 1);
    newMembers.splice(index, 0, draggedMember);
    setCurrentMembers(newMembers);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necesario para permitir el drop
  };

  
  const reorderup = (draggedIndex, index) => {
 
    console.log("end: " + index  + "/" + draggedIndex);
    if (draggedIndex !== undefined) {
      const newMembers = [...currentMembers];
      const [draggedMember] = newMembers.splice(draggedIndex, 1);
      newMembers.splice(index, 0, draggedMember);
      setCurrentMembers(newMembers);
    }
  };
  
  useEffect(() => {
    validate_last_checkpoint();
  }, [token]);

  useEffect(() => {
    get_selected_card_members();
  }, [current_tarjeta]);

  useEffect(() => {
    const table = tableRef.current;

    const touchMoveHandler = (e) => {
      e.preventDefault(); // Prevenir scroll mientras se arrastra
    };

    // Registrar manualmente el evento touchmove con passive: false
    table.addEventListener("touchmove", touchMoveHandler, { passive: false });

    return () => {
      table.removeEventListener("touchmove", touchMoveHandler);
    };
  }, []);

  const handlebt_create_checkpoint = () => {
    currentMembers.forEach((element, index) => {
      element.peso = peso(currentMembers.length, index + 1, 100);
    });



    async function fetchSave_contributions(current_card, currentMemberList) {
      const save_members = await create_contributions(token, currentMemberList, current_card.id);
      if (save_members === "INVALID") {
        navigate("/", { state: { parametro: "talent_step2" } });
      } else if (save_members === "DONE")  {
        
      }
    }
    

    if (indice < cards.length-1) {
      fetchSave_contributions(current_tarjeta, currentMembers);
      setcurrent_tarjeta(cards[indice + 1]);
      setIndice(indice + 1);
    } else {
      fetchSave_contributions(current_tarjeta, currentMembers);
      navigate("done");
      console.log("DONE");
    }
  };

  return (
    <div className="containerChk2">
      <div className="right-panel2">
        <div className="button-container">
          <button className="add-cards-btn" onClick={handlebt_create_checkpoint}>
            Siguiente
          </button>
        </div>
        <h4>
          Ordena los miembros según su contribución a la tarjeta durante sprint:
        </h4>
        <h2>
          <a href={current_tarjeta.shortUrl} target="_blank">
            {current_tarjeta.cardname}
          </a>
        </h2>

        <div className="cards-list2">
          <table ref={tableRef}>
            <thead>
              <tr>
                <th>Miembros</th>
              </tr>
            </thead>

            <tbody>
              {currentMembers.map((member, index) => (
                <tr
                  key={index}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, index)}
                
                >
                  <td>{member.usr_name}</td>
                  <td>  <a  onClick={() => reorderup(index+1,index)}>⬇️</a></td>
                  <td>  <a  onClick={() => reorderup(index-1, index)}>⬆️</a></td>
                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
}

export default TalentStep2;



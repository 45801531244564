import React, { useEffect, useRef, useState } from 'react';
import './TalentStep1.css';
import { create_card_checkpoint, create_checkpoint, get_checkpoints, get_current_cards, get_member_cards, peso, set_dedication } from '../../../utils/talent_services';
import { redirect, useNavigate } from 'react-router-dom';
import Members_master from './Members_master';

function TalentStep1 ({usr_id}) {

  const max_num_cards = 10;  // Numero maximo de tarjetas

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  
  const [cards, setCards] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [countTarjetas, setcountTarjetas] = useState("");


/**
 * Trae todas las tarjetas del ultimo checkpoint creado, asociadas a este miembro, solo si aun no tienen costo asociado
 */
function validate_last_checkpoint  ()  {
  async function fetchCheckpoints() {
      const checkpointlist = await get_checkpoints(token);
      if (checkpointlist==="INVALID"){
        navigate("/",  { state: { parametro: 'talent_step1' } });
      }else{
        //Elijo el checkpoint mas reciente que es el elemento 0 porque vienen ordenados por fecha
      
        let checkPoint_id = checkpointlist[0].id;
        console.log ("Checkpoint actual:" + checkPoint_id);
    
        const card_to_evaluate = await get_member_cards(token, checkPoint_id );
        if (card_to_evaluate!=="INVALID"){

            setCards([]);
     
                
            const currentKr = card_to_evaluate.map((celement, index) => {
                    if (celement.cost>0){
                      navigate("/talent_step2");
                    }
                    return {cpCardid: celement.id,  cardname: celement.title, shortUrl:celement.shortURL, index: index };
                  });

                  setcountTarjetas(currentKr.length);
                  setCards(currentKr);
       }
       //Ahora obtengo las tarjetas de este check point
     }
    }
    fetchCheckpoints();
}


useEffect(() => {
  validate_last_checkpoint();
}, [token]); // Solo se vuelve a ejecutar si 'token' cambia


const handleCheckboxChange = (cardindex, e)=>{

  const isChecked = e.target.checked;


  console.dir (selectedCards.length);

  if (isChecked){
    if(selectedCards.length < max_num_cards){
      cards[cardindex].selected = 1;
      setSelectedCards(prevCards => [...prevCards, cards[cardindex]]);
    }
  }else{
    setSelectedCards((prevSelectedCards) => 
      prevSelectedCards.filter(element => element.index !== cardindex)
    );
  }

 
  
}


function set_dedications(selectedCards){
  
  
  if (selectedCards.length === 0){
    alert("No ha seleccionado ninguna tarjeta");
    return;
  }


  selectedCards.forEach((element, index) => {
    console.log(" PESOS " + index + "  " + selectedCards.length);
 
    element.peso = peso( selectedCards.length, index+1, (cards.length>3?80:100));
    
  });

  let peso_general = 20 / (cards.length - selectedCards.length);

  cards.forEach((element, index) => {
   
   if (!(element.peso > 0)){
      element.peso = peso_general;
   }
 
  });


  async function set_dedication_values() {
    const checkpointlist = await set_dedication(token,cards );
    if (checkpointlist==="INVALID"){
      navigate("/");
    }else{
      navigate("/talent_step2");
   }
  }
  set_dedication_values();
 
}

const handlebt_create_checkpoint = () => {
  set_dedications(selectedCards);
}


///////////


  return (
    <div className="containerChk">

      <div className="right-panel">
      <div className='button-container'>
          <button className="add-cards-btn" onClick={handlebt_create_checkpoint} >
            Siguiente
          </button>
        </div>
        <h4> Selecciona en orden de mayor a menor las tarjetas a las que más dedicaste tiempo de las {countTarjetas} en las que participaste</h4>
        <table className="selectedTable">
          <thead>
     
          </thead>
          <tbody>
            {selectedCards.map((card, index) => {
                    return (
                        <tr key={index}>
                        <td className="numbersRow" style={{ width: '10%' }}>
                         {index + 1}
                      </td>
                        <td style={{ width: '90%' }}><a href={card.shortUrl} target="_blank">{card.cardname}</a></td>
                        
                        </tr>
                      )
                }
              )}
          </tbody>
        </table>
        <div className='cards-list'>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Tarjetas</th>
            </tr>
          </thead>
          <tbody>
            {cards.map((card, index) => {
                    return (
                        <tr
                          key={index}
                        >
                        <td>
                        <input
                          type="checkbox"
                          value={card.selected}
                          onChange={(e) => handleCheckboxChange(index, e)} 
                        />
                      </td>
                        <td><a href={card.shortUrl} target="_blank">{card.cardname}</a></td>
                        
                        </tr>
                      )
                }
              )}
          </tbody>
        </table>
        </div>
        </div>
      
      
    </div>
  );
};

export default TalentStep1;

import React, { useState, useEffect, useRef } from 'react';
import { loadIssueType, get_devices_info, save_new_issue } from '../../utils/services';
import './Units_status.css';
import { useNavigate } from 'react-router-dom';

const Units_status = ({usr_id}) => {

  const token = localStorage.getItem("token");
  const [deviceMSG, setIssues] = useState([]);

  const [model, setModel] = useState("");
  const [manufactured_date, setManufactured_date] = useState("");
  const [item_status, setitem_status] = useState("");
  const [warranty_covered, setwarranty_covered] = useState("");
  const [usr_name, setusr_name] = useState("");

  const [newType, setNewType] = useState("");




  const serialInputRef = useRef(null)
  const newAnnotationText = useRef(null)
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState([]); 

  useEffect(() => {

    async function fetch_issueType() {
      const issueTypelist = await loadIssueType(token);
      if (issueTypelist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista: " + issueTypelist[0].name_issue_type);
        const issueTypeNames = issueTypelist.map((element, index) => {
            return { id: element.id, name: element.name_issue_type, index: index };
          });

        issueTypeNames.unshift("Seleccione uno...");
        setDropdownOptions(issueTypeNames);
        
     }
    }

    fetch_issueType();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia



  const newAnnotation = () => {
    console.log ("Anotacion nueva> " + newAnnotationText.current.value  + " type:" + newType + " usrid" + usr_id);
      if(newType === ''){
        alert("Seleccione un tipo");
        return;
      }

    async function fetchGetDevice(theSerial) {
      
       
        const deviceMessages = await save_new_issue(token, theSerial, newAnnotationText.current.value, newType, usr_id );
        if (deviceMessages==="INVALID"){
          navigate("/");
        }else{
            newAnnotationText.current.value = "";
            handleFindBt();
       }
      }
  
      fetchGetDevice(serialInputRef.current.value);

  }

  const handleFindBt = () => {

 
    async function fetchGetDevice(theSerial) {
      
       
      const deviceMessages = await get_devices_info(token, theSerial);
      if (deviceMessages==="INVALID"){
        navigate("/");
      }else{



        setModel (deviceMessages[0].model);
        setManufactured_date (deviceMessages[0].manufactured_date);
        setitem_status (deviceMessages[0].item_status);
        setwarranty_covered (deviceMessages[0].warranty_covered);
        setusr_name (deviceMessages[0].usr_name);

         const deviceIssues = deviceMessages["issues"].map(item => ({
          comment: item.comment,
          date: item.date,
          usr_name: item.usr_name,
          id: item.id,
          name_issue_type:item.name_issue_type
        }));

        setIssues(deviceIssues);

     }
    }

    fetchGetDevice(serialInputRef.current.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFindBt();
    }
  };

  return (
    <div className="container">
      <div className="search-bar">
        <label htmlFor="serial">Serial Number: </label>
        <input type="text" id="serial" ref={serialInputRef} onKeyDown={handleKeyDown} />
        <button onClick={handleFindBt}>Find</button>
      </div>

      <label>Model: {model}  Manufactured: {manufactured_date} by {usr_name} </label>
      <br/>
      <label>Warranty until: {warranty_covered} Status: {item_status} </label>

     
    
      <div className="annotations">
        {deviceMSG.map((anno) => (
          <div key={anno.id} className="annotation">
            <div className="header">
              <span>by: {anno.usr_name} - {anno.date}</span>
              <span className="status">
                {anno.name_issue_type}
                <div className="status-dot"></div>
              </span>
            </div>
            <div className="content" style={{ whiteSpace: "pre-line" }}>
              <p>{anno.comment}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="new-annotation">
        
        <textarea
            id="annotation-text"
            rows="4"
            cols="50"
            ref={newAnnotationText}
        />
        <label htmlFor="annotation-text">New annotation: </label>
        <select   onChange={(e) => setNewType(e.target.value)}>
            {dropdownOptions.map(({ id, name }, index) => (
                <option key={id} value={id}  >
                {id} {name}
                </option>
            ))}
        </select>
        <button onClick={newAnnotation} >Save</button>
        </div>
    </div>


  );
};

export default Units_status;

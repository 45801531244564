import React, { useEffect, useState } from 'react';
import './Projects_relations.css';
import { getPackages, getPackageVersion } from '../../utils/services';
import {  useNavigate } from 'react-router-dom';

function Projects_relations  () {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const [dropdownOptions, setDropdownOptions] = useState([]); // Estado para las opciones del dropdown

  const [serialfield, setSerials] = useState(""); // Estado para las opciones del dropdown
  const [serialCount, setSerialCount] = useState(0); // Estado para las opciones del dropdown
  


  useEffect(() => {

    async function fetchProjectList() {
      const packageslist = await getPackages(token);
      if (packageslist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista: " + packageslist[0].package_name);
        const packageNames = packageslist.map(element => element.package_name);
        setDropdownOptions(packageNames);
        
     }
    }

  //  ftchProjectList();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia

  const process = () =>{


  }

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSerials(value);
    let elements = value.indexOf(",")>0?value.split(","):value.split("\n");
    setSerialCount(elements.length);
    console.log(elements);
  };


  const handleDropdownChange = (e) => {

    console.log("Dropdown changed to:", e.target.value);
    async function fetchVersions(packageName) {
      const packageVersionlist = await getPackageVersion(token, packageName);
      if (packageVersionlist==="INVALID"){
        navigate("/");
      }else{

        console.log("Lista Versions for : " + packageName + ": " +  packageVersionlist[0].description);

        const newVersions = packageVersionlist.map(item => ({
          versionCode: item.version,
          versionName: item.version_name,
          fechaPublish: item.release_date,
          comments: item.description,
        }));

        //setVersions(newVersions);
        
     }
    }

    fetchVersions(e.target.value);
  };

  return (
    <div className="container">
      
      <div className="project-container">
        <p className="project-label">Project name:</p>
        <div className="dropdown">
          <select className="dropdown-select" onChange={handleDropdownChange}>
            {dropdownOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button className="add-button">+</button>
        </div>
      </div>

      <textarea
            name="comments"
            className="textarea"
            value={serialfield}
            onChange={handleInputChange}
          />
          <p>Serial Count:{serialCount}</p>
      <button className="add-button" onClick={process}>Add</button>
    </div>
  );
};

export default Projects_relations;

import React, { useState, useEffect, useRef } from 'react';
import { loadIssueType, get_devices_info, save_new_issue, loadStatusType, update_status } from '../../utils/services';
import './Despachos.css';
import { useNavigate } from 'react-router-dom';

const Despachos = ({usr_id}) => {

  const token = localStorage.getItem("token");
  const [num_seriales, set_num_serials] = useState(0);
  const [num_seriales_procesados, set_procesados] = useState(0);
  const [ok_serials, set_ok_serials] = useState('');
  const [failed_serials, set_failed_serials] = useState('');

  const [fecha, setFecha] = useState('');

  const [warranty_covered, setwarranty_covered] = useState("");
  const [usr_name, setusr_name] = useState("");

  const [status_type, setStatusType] = useState(0);




  const serialInputRef = useRef(null)
  const newAnnotationText = useRef(null)
  const navigate = useNavigate();
  const [dropdownOptions, setDropdownOptions] = useState([]); 

  let seriales;

  useEffect(() => {

    async function fetch_status_type() {
      const statusTypelist = await loadStatusType(token);
      if (statusTypelist==="INVALID"){
        navigate("/");
      }else{

        const statusTypeNames = statusTypelist.map((element, index) => {
            return { id: element.id, name: element.status_name, index: index };
          });
        setDropdownOptions(statusTypeNames);
        
     }
    }

    fetch_status_type();
  }, [token]); // Solo se vuelve a ejecutar si 'token' cambia


  const countSerials =(e) =>{
   
      seriales = e.split('\n');
      set_num_serials (seriales.length);

  }

  const fechaCambio = (e) => {
    console.log("FECHA SELECT:" + e.target.value);
    setFecha(e.target.value);
  };

  const newStatusClick = () => {

    async function fetchChangeStatus() {
      
      if ( serialInputRef.current.value === ""){
        alert("Faltan datos");
        return;
      }

      seriales = serialInputRef.current.value.split('\n');
      set_ok_serials("");
      set_failed_serials("");

      

      let count = 0;
      for (const serial of seriales) {
        count +=1;
        set_procesados(count);
        if (serial !== "") {
            const deviceStatus = await update_status(token, serial, status_type, fecha);
            if (deviceStatus !== "OK") {
                set_failed_serials(prevString => prevString + "\n " + serial);
            } else {
              // 9 Type Logistics annotation in issue_type
              let newAnnotationTextFinal = newAnnotationText.current.value + "\nCambio de estado: " + status_type;
              const deviceIssue = await save_new_issue(token, serial, newAnnotationTextFinal, 9, usr_id);
              if (deviceIssue === "INVALID") {
                set_failed_serials(prevString => prevString + "\n " + serial );
              } else {
                set_ok_serials(prevString => prevString + "\n " + serial);
              }
            }
        }
      }

           
      }
  
      fetchChangeStatus();

  }


  

  return (
    <div className="container">
      <div className="search-bar">
        <label htmlFor="serial">Serial Number(s): </label>
        <br/>

        <textarea
            id="serial"
            rows="8"
            cols="50"
            onChange={(e) => countSerials(e.target.value)}
            ref={serialInputRef}
        />
        <br/>
        <br/>

        <label htmlFor="fecha">Garantía hasta:</label>
        <input
          type="date"
          id="fecha"
          value={fecha}
          onChange={fechaCambio}
        />
         <br/>
        <br/>
        <label htmlFor="serial">Observaciones: </label>
        <br/>
        <textarea
            id="annotation-text"
            rows="4"
            cols="50"
            ref={newAnnotationText}
        />
    
      </div>


        

        <label htmlFor="annotation-text">Establecer estado: </label>
        <select   onChange={(e) => setStatusType(e.target.value)}>
            {dropdownOptions.map(({ id, name }, index) => (
                <option key={id} value={id}  >
                {name}
                </option>
            ))}
        </select>
        <br/>
        <br/>
        <button onClick={newStatusClick}> Guardar </button>
        <label> Se afectarán #: {num_seriales}  seriales.</label>

        <br/>
        <br/>
        <label> Procesados #: {num_seriales_procesados}  seriales.</label>
        <br/>
        <label> Seriales Registados:    <br/>
           {ok_serials} </label>
        <br/>
        <label> Seriales fallidos:    <br/> {failed_serials} </label>

    </div>


  );
};

export default Despachos;
